import gql from 'graphql-tag';

const GET_SPLASH = gql`
  query getSplashLayout(
    $lpid: String
    $bannerLocationName: String!
    $cid: String
    $referId: String
    $freeBagInviteId: String
    $queryParams: String
    $isBoxycharmSubdomain: Boolean!
  ) {
    me {
      showSelectPlan: abResource(key: "PUBLIC_SHOW_HOME_PAGE_PLAN")
      recaptchaEnabled: featureFlag(key: "RECAPTCHA_ENABLED")
      isOptimizedHomepageEnabled: featureFlag(
        key: "PUBLIC_OPTIMIZED_USER_ACQUISITION_FLOW"
      )
      newAuthLoginEnabled: featureFlag(key: "PUBLIC_NEW_AUTH_LOGIN")
      isBlackCTA: abResource(key: "PUBLIC_BLACK_CTA")
      isHomePageNewHeroExperiment: abResource(key: "PUBLIC_HOME_PAGE_NEW_HERO")
      homePageNewHeroExperimentVariantName: abResource(
        key: "PUBLIC_HOME_PAGE_NEW_HERO_VARIANT_NAME"
      )
      isHomepageMembershipSection: abResource(
        key: "PUBLIC_HOME_PAGE_CAROUSEL_PLANS"
      )
      getStartedButtonCohortParam: abResource(
        key: "PUBLIC_GET_STARTED_EXP_COHORT_PARAM"
      )
      layout {
        defaultHomepage: homepage {
          content {
            heroImage {
              mobile {
                src
                alt
              }
              desktop {
                src
                alt
              }
            }
            headline
            rotating
            subHeading {
              mobile
              desktop
            }
            button
            belowButtonDisclaimer
          }
        }
        homepage(
          lpid: $lpid
          referId: $referId
          freeBagInviteId: $freeBagInviteId
        ) {
          content {
            heroImage {
              mobile {
                src
                alt
              }
              desktop {
                src
                alt
              }
            }
            headline
            rotating
            subHeading {
              mobile
              desktop
            }
            button
            belowButtonDisclaimer
          }
          creatorLanding {
            gift {
              image {
                src
                alt
              }
              rating
              testimonials
            }
            header
            description
          }
          whyChoose {
            images {
              src
              alt
            }
          }
          featured {
            title
            logos {
              src
              alt
            }
          }
          howItWorks {
            sections {
              title
              description
            }
          }
          isGetStartedButtonInMiddle
        }
        selectPlanTitle: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "homePageSelectPlanTitle" }
        )
        selectPlanGBBanner: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "homePageSelectPlanGBBanner" }
        )
        selectPlanGBImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "homePageSelectPlanGBImage.jpg" }
        )
        selectPlanGBHighlights: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "homePageSelectPlanGBHighlights" }
        )
        selectPlanGBPBanner: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "homePageSelectPlanGBPBanner" }
        )
        selectPlanGBPImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "homePageSelectPlanGBPImage.jpg" }
        )
        selectPlanGBPHighlights: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "homePageSelectPlanGBPHighlights" }
        )
        selectPlanCombinedBanner: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "homePageSelectPlanCombinedBanner" }
        )
        selectPlanCombinedImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "homePageSelectPlanCombinedImage.jpg" }
        )
        selectPlanCombinedProgramName: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "homePageSelectPlanCombinedProgramName" }
        )
        selectPlanCombinedHighlights: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "homePageSelectPlanCombinedHighlights" }
        )
        selectPlanButton: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "homePageSelectPlanButton" }
        )
        spackIcon: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "spark-icon.png" }
        )
        profileIcon: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "profile-250x250.png" }
        )

        newHomePageHeroVariant1DesktopImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "newHomePageHeroVariant1DesktopImage.jpg" }
        )
        newHomePageHeroVariant1MobileImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "newHomePageHeroVariant1MobileImage.jpg" }
        )
        newHomePageHeroVariant1Heading: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "newHomePageHeroVariant1Heading" }
        )
        newHomePageHeroVariant1Subheading: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "newHomePageHeroVariant1Subheading" }
        )

        newHomePageHeroVariant2DesktopImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "newHomePageHeroVariant2DesktopImage.jpg" }
        )
        newHomePageHeroVariant2MobileImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "newHomePageHeroVariant2MobileImage.jpg" }
        )
        newHomePageHeroVariant2Heading: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "newHomePageHeroVariant2Heading" }
        )
        newHomePageHeroVariant2Subheading: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "newHomePageHeroVariant2Subheading" }
        )

        newHomePageHeroVariant3DesktopImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "newHomePageHeroVariant3DesktopImage.jpg" }
        )
        newHomePageHeroVariant3MobileImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "newHomePageHeroVariant3MobileImage.jpg" }
        )
        newHomePageHeroVariant3Heading: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "newHomePageHeroVariant3Heading" }
        )
        newHomePageHeroVariant3Subheading: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "newHomePageHeroVariant3Subheading" }
        )

        newHomePageHeroVariant4DesktopImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "newHomePageHeroVariant4DesktopImage.jpg" }
        )
        newHomePageHeroVariant4MobileImage: vcmsAsset(
          structure: "ImageAsset"
          properties: { fileName: "newHomePageHeroVariant4MobileImage.jpg" }
        )
        newHomePageHeroVariant4Heading: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "newHomePageHeroVariant4Heading" }
        )
        newHomePageHeroVariant4Subheading: vcmsAsset(
          structure: "TextAsset"
          properties: { key: "newHomePageHeroVariant4Subheading" }
        )
        homepageMembershipSection: vcmsAsset(
          structure: "HomepageMembershipSection"
          properties: { id: "PRE-CAROUSEL" }
        )

        loginModuleBanner: vcmsAsset(
          structure: "LoginModuleBanner"
          properties: { id: "ipsy-login-banner" }
        ) @skip(if: $isBoxycharmSubdomain)

        loginModuleBannerBoxy: vcmsAsset(
          structure: "LoginModuleBanner"
          properties: { id: "boxy-login-banner" }
        ) @include(if: $isBoxycharmSubdomain)

        isLoginModalBannerOn: globalVariable(key: "PUBLIC_LOGIN_BANNER")
      }
      glambagMonthlyPrice: membershipCosts(
        program: GLAMBAG
        period: 1
        cadence: 1
      ) {
        price {
          amount
        }
      }
      fullsizeMonthlyPrice: membershipCosts(
        program: FULLSIZE
        period: 1
        cadence: 1
      ) {
        price {
          amount
        }
      }
      banner(
        locationName: $bannerLocationName
        campaignId: $cid
        queryParams: $queryParams
      ) {
        name
        ... on BannerRaw {
          properties
        }
      }
    }
  }
`;

export default GET_SPLASH;

import { GQLSimpleImage } from '../../../../gql.generated';
import { ExtendedGQLQuery as ExtendedGQLQueryRefreshments } from '../../graphql/hooks/useGetRefreshmentsHpBanner';

export type RefreshmentsHpBannerType = {
  desktopBanner: GQLSimpleImage;
  mobileBanner: GQLSimpleImage;
  topBannerTitle?: string;
  header?: string;
  ctaUrl?: string;
  ctaTitle?: string;
  titleColor?: string;
  headerColor?: string;
  giftImage?: GQLSimpleImage;
  isGWS?: boolean;
};

const getRefreshmentsHpBanner = (
  data?: ExtendedGQLQueryRefreshments,
  isGWS?: boolean
): RefreshmentsHpBannerType | undefined => {
  const { topbannerdesktopimage, topbannermobileimage } =
    data?.me?.layout?.refreshmentsHpBanner || {};

  const {
    title,
    header,
    giftTitle,
    giftHeader,
    ctaUrl,
    ctaTitle,
    titleColor,
    headerColor,
    giftImage,
  } = data?.me?.layout?.homepageTopBanner || {};

  if (topbannerdesktopimage?.src) {
    return {
      topBannerTitle: isGWS ? giftTitle : title,
      header: isGWS ? giftHeader : header,
      ctaUrl,
      ctaTitle,
      headerColor,
      titleColor,
      giftImage,
      isGWS,
      desktopBanner: topbannerdesktopimage,
      mobileBanner: topbannermobileimage,
    };
  }
  return undefined;
};

export default getRefreshmentsHpBanner;

import { useQuery } from '@apollo/client';

import {
  GQLHomepageContent,
  GQLLayoutContent,
  GQLLink,
  GQLMyData,
  GQLQuery,
  Maybe,
  Scalars,
} from '../../../../gql.generated';
import { BannerLocations } from '../../../Banners/types';
import { HomepageMembershipSection, LoginBannerModule } from '../../types';
import GET_SPLASH from '../queries/GET_SPLASH';

interface ExtendedLayout extends GQLLayoutContent {
  programPagesLinks: Maybe<GQLLink>[] | undefined;
  defaultHomepage: GQLHomepageContent;
  selectPlanTitle: GQLLayoutContent['vcmsAsset'];
  selectPlanGBBanner: GQLLayoutContent['vcmsAsset'];
  selectPlanGBImage: GQLLayoutContent['vcmsAsset'];
  selectPlanGBHighlights: GQLLayoutContent['vcmsAsset'];
  selectPlanGBPBanner: GQLLayoutContent['vcmsAsset'];
  selectPlanGBPImage: GQLLayoutContent['vcmsAsset'];
  selectPlanGBPHighlights: GQLLayoutContent['vcmsAsset'];
  selectPlanCombinedBanner: GQLLayoutContent['vcmsAsset'];
  selectPlanCombinedImage: GQLLayoutContent['vcmsAsset'];
  selectPlanCombinedProgramName: GQLLayoutContent['vcmsAsset'];
  selectPlanCombinedHighlights: GQLLayoutContent['vcmsAsset'];
  selectPlanButton: GQLLayoutContent['vcmsAsset'];
  spackIcon: GQLLayoutContent['vcmsAsset'];
  profileIcon: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant1DesktopImage: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant1MobileImage: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant1Heading: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant1Subheading: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant2DesktopImage: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant2MobileImage: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant2Heading: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant2Subheading: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant3DesktopImage: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant3MobileImage: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant3Heading: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant3Subheading: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant4DesktopImage: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant4MobileImage: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant4Heading: GQLLayoutContent['vcmsAsset'];
  newHomePageHeroVariant4Subheading: GQLLayoutContent['vcmsAsset'];
  loginModuleBanner: LoginBannerModule;
  loginModuleBannerBoxy: LoginBannerModule;
  isLoginModalBannerOn: Maybe<Scalars['String']['output']>;
  homepageMembershipSection: HomepageMembershipSection;
}

interface ExtendedMyData extends GQLMyData {
  showSelectPlan?: GQLMyData['abResource'];
  isHomePageNewHeroExperiment?: GQLMyData['abResource'];
  homePageNewHeroExperimentVariantName?: GQLMyData['abResource'];
  isBlackCTA?: GQLMyData['abResource'];
  referralLpStyleAsFreebag?: GQLMyData['abResource'];
  glambagMonthlyPrice: GQLMyData['membershipCosts'];
  fullsizeMonthlyPrice: GQLMyData['membershipCosts'];
  layout: ExtendedLayout;
  isHomepageMembershipSection?: GQLMyData['abResource'];
  recaptchaEnabled?: boolean;
  isOptimizedHomepageEnabled?: boolean;
  newAuthLoginEnabled: GQLMyData['featureFlag'];
  getStartedButtonCohortParam?: GQLMyData['abResource'];
}

export interface ExtendedGQLQuery extends GQLQuery {
  me: ExtendedMyData;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetSplash = (
  lpid = 'default',
  cid: string,
  isBoxycharmSubdomain?: boolean,
  referId?: string,
  freeBagInviteId?: string,
  queryParams?: string
) =>
  useQuery<ExtendedGQLQuery>(GET_SPLASH, {
    variables: {
      lpid,
      bannerLocationName: BannerLocations.SIGNED_OUT_HOMEPAGE,
      cid,
      queryParams,
      referId,
      freeBagInviteId,
      isBoxycharmSubdomain,
    },
  });

export default useGetSplash;

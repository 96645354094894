/* eslint-disable sonarjs/cognitive-complexity */
import { ApolloError } from '@apollo/client';
import { amplitudeService } from '@bfa/amplitude-events';
import {
  SPLASH_PAGE_CATEGORY,
  useLogConversionEvent,
  VIEW_CONTENT_EVENT,
} from '@bfa/capi';
import { Config } from '@bfa/next-app-config';
import { externalRedirect, getFirstParam } from '@bfa/nextjs-common/utils';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { DefaultTheme } from 'styled-components';
import Uri from 'urijs';

import { ABSOLUTE_URLS } from '../../../../constants';
import {
  GQLBannerRaw,
  GQLHomepageContent,
  GQLLayoutContent,
  Maybe,
} from '../../../../gql.generated';
import useGetStartedAnalytics from '../../../../hooks/useGetStartedAnalytics';
import {
  buildDeferredEventsUrl,
  logAnalyticsEvent,
} from '../../../../utils/analytics';
import isInBoxycharmSubdomain from '../../../../utils/isBoxycharmSubdomain';
import { CmsImage } from '../../../../utils/types';
import useGetRefreshmentsHpBanner from '../../graphql/hooks/useGetRefreshmentsHpBanner';
import useGetSplash, {
  ExtendedGQLQuery as ExtendedGQLQuerySplash,
} from '../../graphql/hooks/useGetSplash';
import { HomepageMembershipSection, LoginBannerModule } from '../../types';
import { initLoginEvent } from '../../utils/analytics';
import getRefreshmentsHpBanner, {
  RefreshmentsHpBannerType,
} from '../helpers/getRefreshmentsBanner';

export const REFERRAL_LANDING_PAGE_ID = 'referral_landing_page';
export const FREE_BAG_LANDING_PAGE_ID = 'free_bag_landing_page';
const SID_GWS = 'gws';

type ExtendedLayout = {
  programPagesLinks: GQLLayoutContent['vcmsAsset'];
};

export type SelectPlanType = {
  mostPopular?: boolean;
  packageTypes: string;
  program: string;
  color: keyof DefaultTheme['colors'];
  bgColor: string;
  banner: string;
  icon?: CmsImage;
  image: CmsImage;
  highlights: string;
  price: number;
  button: string;
};

type SelectPlanDataType = {
  showSelectPlan?: boolean;
  selectPlanTitle: string;
  selectPlans: Array<SelectPlanType>;
};

type NewHeroExperimentType = 'Variant1' | 'Variant2' | 'Variant3' | 'Variant4';

type SplashActions = {
  loading: boolean;
  error?: ApolloError;
  homepage?: GQLHomepageContent;
  refreshmentsHpBanner?: RefreshmentsHpBannerType;
  selectPlanData: SelectPlanDataType;
  banner?: Maybe<GQLBannerRaw>;
  showLoginModal: boolean;
  toggleLoginModal: (show: boolean) => void;
  onHeaderGetStartedClick: () => void;
  onLoginClick: () => void;
  homepageMembershipSection?: HomepageMembershipSection;
  isHomepageMembershipSection?: boolean;
  loginModuleBanner?: LoginBannerModule;
  isLoginModalBannerOn: boolean;
  showBoxycharmLogo?: boolean;
  recaptchaEnabled: boolean;
  isOptimizedHomepageEnabled: boolean;
};

const getHomepage = (
  data?: ExtendedGQLQuerySplash,
  isInviteeLp?: boolean
): GQLHomepageContent | undefined => {
  if (isInviteeLp) {
    // Get dynamic "lpid" CMS Content to Referral and Free Bag Landing Pages
    return data?.me.layout?.homepage;
  }

  // Another Landing Page Contents
  return {
    ...data?.me.layout?.homepage,
    ...data?.me.layout?.defaultHomepage,
  } as GQLHomepageContent;
};

const getSelectPlan = (data?: ExtendedGQLQuerySplash): SelectPlanDataType => {
  const glambagMonthlyPrice = data?.me?.glambagMonthlyPrice?.price?.amount ?? 0;
  const fullsizeMonthlyPrice =
    data?.me?.fullsizeMonthlyPrice?.price?.amount ?? 0;
  return {
    showSelectPlan: data?.me?.showSelectPlan === 'true',
    selectPlanTitle: data?.me?.layout?.selectPlanTitle?.value ?? '',
    selectPlans: [
      {
        mostPopular: true,
        packageTypes: 'MONTHLY',
        program: 'Glam Bag',
        color: 'pinkIpsy',
        bgColor: '#FDE4E4',
        banner: data?.me?.layout?.selectPlanGBBanner?.value ?? '',
        icon: data?.me?.layout?.spackIcon,
        image: data?.me?.layout?.selectPlanGBImage,
        highlights: data?.me?.layout?.selectPlanGBHighlights?.value ?? '',
        price: glambagMonthlyPrice,
        button: data?.me?.layout?.selectPlanButton?.value,
      },
      {
        packageTypes: 'MONTHLY_FULLSIZE',
        program: 'Glam Bag Plus',
        color: 'tangerine',
        bgColor: '#FED7BF',
        banner: data?.me?.layout?.selectPlanGBPBanner?.value ?? '',
        image: data?.me?.layout?.selectPlanGBPImage,
        highlights: data?.me?.layout?.selectPlanGBPHighlights?.value ?? '',
        price: fullsizeMonthlyPrice,
        button: data?.me?.layout?.selectPlanButton?.value,
      },
      {
        packageTypes: 'MONTHLY,MONTHLY_FULLSIZE',
        program:
          data?.me?.layout?.selectPlanCombinedProgramName?.value ?? 'Combined',
        color: 'navy',
        bgColor: '#F4EDFD',
        banner: data?.me?.layout?.selectPlanCombinedBanner?.value ?? '',
        image: data?.me?.layout?.selectPlanCombinedImage,
        highlights: data?.me?.layout?.selectPlanCombinedHighlights?.value ?? '',
        price: glambagMonthlyPrice + fullsizeMonthlyPrice,
        button: data?.me?.layout?.selectPlanButton?.value,
      },
    ],
  };
};

const getHomePageWithExperiment = (
  data?: ExtendedGQLQuerySplash,
  isNewHomePageExperiment?: boolean,
  isInviteeLp?: boolean
): GQLHomepageContent | undefined => {
  if (isNewHomePageExperiment) {
    const experimentVariantName = data?.me
      ?.homePageNewHeroExperimentVariantName as NewHeroExperimentType;
    const buttonText = 'Get Started';
    const newContentData = {
      content: {
        heroImage: {
          desktop:
            data?.me?.layout[
              `newHomePageHero${experimentVariantName}DesktopImage`
            ],
          mobile:
            data?.me?.layout[
              `newHomePageHero${experimentVariantName}MobileImage`
            ],
        },
        button: `${buttonText}`,
        headline:
          data?.me?.layout[`newHomePageHero${experimentVariantName}Heading`]
            ?.value,
        subHeading: {
          mobile:
            data?.me?.layout[
              `newHomePageHero${experimentVariantName}Subheading`
            ]?.value,
          desktop:
            data?.me?.layout[
              `newHomePageHero${experimentVariantName}Subheading`
            ]?.value,
        },
      },
    };
    return (
      (data && { ...data.me.layout.homepage, ...newContentData }) ||
      data?.me.layout.homepage
    );
  }

  return getHomepage(data, isInviteeLp);
};

const getLpid = (
  lpid?: string,
  isReferralLp?: boolean,
  isFreeBagLp?: boolean
) => {
  if (isReferralLp) {
    return REFERRAL_LANDING_PAGE_ID;
  }

  if (isFreeBagLp) {
    return FREE_BAG_LANDING_PAGE_ID;
  }

  return lpid;
};

const useSplashActions = (
  isBoxycharmSubdomain?: boolean,
  lpid?: string,
  referId?: string,
  freeBagInviteId?: string,
  isReferralLp?: boolean,
  isFreeBagLp?: boolean
): SplashActions => {
  const { query, push } = useRouter();
  const queryParams = new URLSearchParams(
    query as Record<string, string>
  ).toString();
  const cid = getFirstParam(query.cid);
  const sid = getFirstParam(query.sid);
  const currentLpid = getLpid(lpid, isReferralLp, isFreeBagLp);
  const isInviteeLp = isReferralLp || isFreeBagLp;
  const { data, loading, error } = useGetSplash(
    currentLpid,
    cid ?? '',
    isBoxycharmSubdomain ?? isInBoxycharmSubdomain(),
    referId,
    freeBagInviteId,
    queryParams
  );
  const { data: refreshmentsHpBannerData } = useGetRefreshmentsHpBanner({
    isInviteeLp,
  });

  const isOptimizedHomepageEnabled =
    data?.me?.isOptimizedHomepageEnabled ?? false;
  const isForceLoginOrPasswordReset =
    query.forceLogin === '1' || query.passwordIsReset === '1';

  const [showLoginModal, setShowLoginModal] = useState(
    isForceLoginOrPasswordReset
  );
  const [banner, setBanner] = useState<Maybe<GQLBannerRaw> | undefined>(
    data?.me?.banner
  );

  const triggerLoginEvent = useCallback(initLoginEvent(), []);

  const homepage = getHomePageWithExperiment(
    data,
    data?.me.isHomePageNewHeroExperiment === 'true',
    isInviteeLp
  );

  const toggleLoginModal = (show: boolean) => {
    triggerLoginEvent({
      type: 'default',
      label: show ? 'Login Modal Open' : 'Login Modal Close',
    });

    if (show) {
      amplitudeService.login.modalViewed({
        isForceLogin: false,
        isPasswordReset: false,
      });
    } else {
      amplitudeService.login.modalClosed({});
    }

    setShowLoginModal(show);
  };
  const getStartedButtonHeaderEvent = useGetStartedAnalytics('HEADER');
  const onHeaderGetStartedClick = () => {
    const cohortParam = data?.me?.getStartedButtonCohortParam;

    let quizBaseUrl = ABSOLUTE_URLS.QUIZ;
    if (cohortParam) {
      quizBaseUrl = new Uri(quizBaseUrl)
        .addQuery('exp_cohort', cohortParam)
        .href();
    }
    const deferredQuizUri = buildDeferredEventsUrl(
      quizBaseUrl,
      getStartedButtonHeaderEvent
    );
    externalRedirect(deferredQuizUri);
  };

  useEffect(() => {
    if (isForceLoginOrPasswordReset && !isOptimizedHomepageEnabled) {
      triggerLoginEvent({
        type: 'default',
        label: 'Login Modal Open',
      });
      amplitudeService.login.modalViewed({
        isForceLogin: query.forceLogin === '1',
        isPasswordReset: query.passwordIsReset === '1',
      });
    }
  }, [isForceLoginOrPasswordReset, isOptimizedHomepageEnabled]);

  useEffect(() => {
    logAnalyticsEvent({
      name: 'SIGNED_OUT_SPLASH_SPA',
      label: 'Visited from SPA-NEXT',
    });
  }, []);

  useEffect(() => {
    if (data?.me?.banner) {
      setBanner(data?.me?.banner);
    }
  }, [data]);

  useLogConversionEvent({
    ...VIEW_CONTENT_EVENT,
    pageCategory: SPLASH_PAGE_CATEGORY,
    shouldTriggerFacebookPixel: true,
  });

  const onLoginClick = () => {
    const newAuthLoginEnabled = data?.me?.newAuthLoginEnabled ?? false;
    if (newAuthLoginEnabled) {
      push('/api/auth/login');
      return;
    }
    toggleLoginModal(true);
  };

  return {
    loading,
    error,
    homepage,
    refreshmentsHpBanner: getRefreshmentsHpBanner(
      refreshmentsHpBannerData,
      sid === SID_GWS
    ),
    selectPlanData: getSelectPlan(data),
    banner,
    showLoginModal,
    toggleLoginModal,
    onHeaderGetStartedClick,
    onLoginClick,
    homepageMembershipSection: data?.me?.layout?.homepageMembershipSection,
    isHomepageMembershipSection: !!data?.me?.isHomepageMembershipSection,
    loginModuleBanner: !isBoxycharmSubdomain
      ? data?.me?.layout?.loginModuleBanner
      : data?.me?.layout?.loginModuleBannerBoxy,
    isLoginModalBannerOn:
      data?.me?.layout?.isLoginModalBannerOn?.toLowerCase() === 'true',
    showBoxycharmLogo: isBoxycharmSubdomain ?? isInBoxycharmSubdomain(),
    recaptchaEnabled:
      (data?.me?.recaptchaEnabled ?? false) &&
      Config.public.misc.googleReCaptchaV3Key !== '',
    isOptimizedHomepageEnabled,
  };
};

export default useSplashActions;

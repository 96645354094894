import { NextPageContext } from 'next';

const isBoxycharmSubdomain = (
  ctx?: NextPageContext | null
): boolean | undefined => {
  if (ctx?.req) {
    return ctx.req.headers?.host?.includes('boxycharm');
  } else if (typeof window !== 'undefined') {
    return window.location.host.includes('boxycharm');
  }

  return false;
};

export default isBoxycharmSubdomain;

import { useQuery } from '@apollo/client';

import { GQLMyData, GQLQuery } from '../../../../gql.generated';
import GET_QUIZ_EXPERIMENT from '../queries/GET_QUIZ_EXPERIMENT';

interface ExtendedMyData extends GQLMyData {
  showSelectPlan: GQLMyData['abResource'];
  getStartedButtonCohortParam: GQLMyData['abResource'];
  isOptimizedFlow?: boolean;
}

interface ExtendedGQLQuery extends GQLQuery {
  me: ExtendedMyData;
}

const useGetQuizExperiment = () =>
  useQuery<ExtendedGQLQuery>(GET_QUIZ_EXPERIMENT);

export default useGetQuizExperiment;

import { LoginProps } from '@bfa/accounts-forms';
import { getSegmentAnalyticsClient } from '@bfa/capi';
import { clientLogging } from '@bfa/nextjs-common/services';
import {
  externalRedirect,
  externalSecureSubdomainRedirect,
  getFirstParam,
} from '@bfa/nextjs-common/utils';
import { datadogRum } from '@datadog/browser-rum';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { ABSOLUTE_URLS } from '../../../../../constants';
import { logAnalyticsEvent } from '../../../../../utils/analytics';

/**
 * Performs external redirect to Splash so request goes through EGW Experience Prioritization plugin.
 */
const externalRedirectToSplash = () => {
  externalRedirect(ABSOLUTE_URLS.SPLASH);
};

const useLoginActions = (eventsExtraProperties?: any) => {
  const { query } = useRouter();
  const [passwordResetUrl, setPasswordResetUrl] = useState(
    ABSOLUTE_URLS.FORGOT_PASSWORD
  );

  useEffect(() => {
    const subdomainToReplace = 'boxycharm';
    const origin = window.location.origin;
    if (origin.toLowerCase().includes(subdomainToReplace)) {
      setPasswordResetUrl(
        window.location.origin.replace('boxycharm', 'www') +
          ABSOLUTE_URLS.FORGOT_PASSWORD
      );
    }
  }, []);

  // Checks if user landed from forgot password email
  // which contains cid of the form `Forgot Password_<campaignId>` or TX_ForgotPassword
  const landedFromForgotPasswordEmail = (): boolean => {
    const cid = getFirstParam(query.cid);
    return (
      cid?.startsWith('Forgot Password') ||
      cid?.startsWith('Forgot+Password') ||
      cid?.includes('ForgotPassword') ||
      false
    );
  };

  const handleLoginSuccess: LoginProps['onLoginSuccess'] = async ({
    userId,
    passwordExpired,
  }) => {
    if (userId) {
      datadogRum.setUser({ id: userId });
      const segmentClient = getSegmentAnalyticsClient();
      segmentClient.identify(userId, {});
    }

    if (passwordExpired) {
      logAnalyticsEvent({
        name: 'SIGNED_OUT_LANDING_EXPERIENCE',
        label: `Login With Password Succeeded - Redirect to password expired`,
        properties: eventsExtraProperties,
      });
      externalRedirect('/account/change-password?passwordIsReset=1');
      return;
    }

    const redirectUrl = getFirstParam(query.redirectUrl);

    if (redirectUrl) {
      clientLogging.logInfo({
        content: `splash: user login successful - redirect to ${redirectUrl}`,
      });

      logAnalyticsEvent({
        name: 'SIGNED_OUT_LANDING_EXPERIENCE',
        label: `Login With Password Succeeded - Force redirect to ${redirectUrl}`,
        properties: eventsExtraProperties,
      });
      if (externalSecureSubdomainRedirect(redirectUrl)) {
        return;
      }
    }

    clientLogging.logInfo({
      content: 'splash: user login successful - redirect to homepage',
    });
    logAnalyticsEvent({
      name: 'SIGNED_OUT_LANDING_EXPERIENCE',
      label: `Login With Password Succeeded - No force redirect`,
      properties: eventsExtraProperties,
    });

    externalRedirectToSplash();
  };

  return {
    handleLoginSuccess,
    passwordResetUrl,
    showPasswordResetMessage: query.passwordIsReset === '1',
    isForgotPassword: landedFromForgotPasswordEmail(),
    redirectUrl: getFirstParam(query.redirectUrl),
  };
};

export default useLoginActions;

import { Login } from '@bfa/accounts-forms';
import { Column, Container, Row, Text } from '@bfa/common-ui';
import { Config } from '@bfa/next-app-config';
import React, { FC } from 'react';

import renderHtml from '../../../../utils/renderHtml';
import useLoginActions from '../../pages/hooks/useLoginActions';
import { LoginFormWrapper } from './LoginForm.styled';

export type LoginFormPropsType = {
  disclaimer?: string;
  eventsExtraProperties?: any;
  recaptchaEnabled: boolean;
  title?: string;
};

const LoginForm: FC<LoginFormPropsType> = ({
  title = 'Log In',
  disclaimer,
  eventsExtraProperties = {},
  recaptchaEnabled,
}) => {
  const {
    passwordResetUrl,
    handleLoginSuccess,
    isForgotPassword,
    redirectUrl,
    showPasswordResetMessage,
  } = useLoginActions(eventsExtraProperties);

  return (
    <LoginFormWrapper>
      <Container mt={'2rem'} px={['1.5rem', '4rem']}>
        <Login
          reCaptchaConfig={{
            v3SiteKey: Config.public.misc.googleReCaptchaV3Key,
            v2SiteKey: Config.public.misc.googleReCaptchaV2Key,
            enabled: recaptchaEnabled,
            polling: 100,
          }}
          customEventProperty={{ ...eventsExtraProperties, redirectUrl }}
          title={title}
          onLoginSuccess={handleLoginSuccess}
          forgotPasswordUrl={passwordResetUrl}
          isForgotPasswordFlow={isForgotPassword}
          isPasswordResetFlow={showPasswordResetMessage}
        />
        {disclaimer && (
          <Row>
            <Column textAlign="center">
              <Text
                color="grayMedium"
                fontSize="1.2rem"
                lineHeight="1.6rem"
                textAlign="center"
                paddingX="1.5rem"
              >
                {renderHtml(disclaimer)}
              </Text>
            </Column>
          </Row>
        )}
      </Container>
    </LoginFormWrapper>
  );
};

LoginForm.displayName = 'LoginForm';

export default LoginForm;

import React, { ReactNode } from 'react';
import reactHtmlParser from 'react-html-parser';
import sanitize from 'sanitize-html';

export const defaultSanitizeOptions: sanitize.IOptions = {
  allowedTags: [
    'h2',
    'h3',
    'b',
    'i',
    'em',
    'strong',
    'a',
    'p',
    'br',
    'ul',
    'ol',
    'li',
    'hr',
    'span',
    'table',
    'tr',
    'th',
    'td',
  ],
  allowedAttributes: {
    a: ['href', 'style', 'class', 'target'],
    h2: ['id'],
    p: ['style'],
    li: ['style'],
    table: ['style'],
    tr: ['style'],
    td: ['style'],
    th: ['style', 'colspan'],
  },
};

function visitNodes(nodes: any[]): void {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node.type === 'tag') {
      if (node.name === 'a' && !node.attribs.referrerpolicy) {
        node.attribs.referrerpolicy = 'no-referrer';
      }

      visitNodes(node.children);
    }
  }
}

const renderHtml = (
  html: string,
  options = defaultSanitizeOptions
): ReactNode => (
  <>
    {reactHtmlParser(sanitize(html, options), {
      preprocessNodes(nodes: []): any {
        visitNodes(nodes);
        return nodes;
      },
    })}
  </>
);

export default renderHtml;

import { Button } from '@bfa/common-ui';
import { getFirstParam } from '@bfa/nextjs-common/utils';
import { useRouter } from 'next/router';
import React, { ComponentProps, FC } from 'react';
import URI from 'urijs';

import { AnalyticsLink } from '../../../../../components/Analytics';
import { ABSOLUTE_URLS } from '../../../../../constants';
import useGetStartedAnalytics, {
  EventLabelMap,
} from '../../../../../hooks/useGetStartedAnalytics';
import useGetQuizExperiment from '../../../graphql/hooks/useGetQuizExperiment';

type Props = {
  type?: keyof EventLabelMap;
} & ComponentProps<typeof Button>;

const GetStartedButton: FC<Props> = ({ type, ...buttonProps }) => {
  const router = useRouter();
  const { data } = useGetQuizExperiment();
  const defaultProgram = getFirstParam(router.query.defaultProgram);
  const cohortParam = data?.me?.getStartedButtonCohortParam;

  let redirectUrl = ABSOLUTE_URLS.QUIZ;

  if (defaultProgram) {
    redirectUrl = new URI(redirectUrl)
      .addQuery('defaultProgram', defaultProgram)
      .href();
  }

  if (cohortParam) {
    redirectUrl = new URI(redirectUrl)
      .addQuery('exp_cohort', cohortParam)
      .href();
  }

  const analyticsEvent = useGetStartedAnalytics(type);

  if (data?.me?.isOptimizedFlow) {
    redirectUrl = `${ABSOLUTE_URLS.SUB_SPA_CHOOSE_PAGE}?cid=optimized-homepage`;
  }

  return (
    <AnalyticsLink {...analyticsEvent} href={redirectUrl} passHref>
      <Button {...buttonProps} href={redirectUrl} data-home-cta="true" />
    </AnalyticsLink>
  );
};

export default GetStartedButton;

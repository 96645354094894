import { useQuery } from '@apollo/client';

import {
  GQLLayoutContent,
  GQLMyData,
  GQLQuery,
  GQLSimpleImage,
} from '../../../../gql.generated';
import GET_REFRESHMENTS_HP_BANNER from '../queries/GET_REFRESHMENTS_HP_BANNER';

interface ExtendedLayout extends GQLLayoutContent {
  refreshmentsHpBanner: GQLLayoutContent['vcmsAsset'];
  homepageTopBanner: {
    title?: string;
    header?: string;
    giftTitle?: string;
    giftHeader?: string;
    ctaUrl?: string;
    ctaTitle?: string;
    titleColor?: string;
    headerColor?: string;
    giftImage?: GQLSimpleImage;
  };
}

interface ExtendedMyData extends GQLMyData {
  layout: ExtendedLayout;
}

export interface ExtendedGQLQuery extends GQLQuery {
  me: ExtendedMyData;
}

type Props = {
  isInviteeLp?: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGetRefreshmentsHpBanner = (
  { isInviteeLp }: Props = { isInviteeLp: false }
) =>
  useQuery<ExtendedGQLQuery>(GET_REFRESHMENTS_HP_BANNER, {
    skip: isInviteeLp,
  });

export default useGetRefreshmentsHpBanner;

import { externalRedirect, getAuthInfo, isDev } from '@bfa/nextjs-common/utils';
import gql from 'graphql-tag';
import { NextPage, NextPageContext, NextPageWithInitialProps } from 'next';
import React from 'react';

import { ABSOLUTE_URLS, CAMPAIGN_NAMES } from '../../../../constants';
import { GQLMyData } from '../../../../gql.generated';
import { initApolloClient } from '../../../../utils/apollo';

type GQLQuery = {
  me: Pick<GQLMyData, 'campaigns'>;
};

export const GET_CAMPAIGNS = gql`
  query getCampaigns {
    me {
      campaigns {
        id
        name
      }
    }
  }
`;

const hasValidFreeBagInvite = async (
  ctx: NextPageContext
): Promise<boolean> => {
  const client = initApolloClient({}, ctx);
  const result = await client.query<GQLQuery>({
    query: GET_CAMPAIGNS,
    fetchPolicy: 'network-only',
  });

  return !!result.data?.me.campaigns.find(
    (campaign) => campaign.name === CAMPAIGN_NAMES.FREEBAG
  );
};

/**
 * Redirects authenticated users with valid free bag invite to the Subscribe page.
 *
 * @param PageComponent - The component to enhance.
 * @returns A component wrapped with free bag invite redirect.
 */
const withRedirectFreeBagUser = <Props, InitialProps>(
  PageComponent: NextPage<Props, InitialProps>
): NextPageWithInitialProps<Props, InitialProps | Record<string, unknown>> => {
  const WrappedPageComponent: NextPageWithInitialProps<
    Props,
    InitialProps | Record<string, unknown>
  > = (props) => {
    return <PageComponent {...props} />;
  };

  if (isDev) {
    const displayName =
      PageComponent.displayName || PageComponent.name || 'Component';
    WrappedPageComponent.displayName = `withRedirectFreeBagUser(${displayName})`;
  }

  WrappedPageComponent.getInitialProps = async (ctx) => {
    const authInfo = getAuthInfo(ctx);
    const isPartialLogin = !!authInfo?.tokenType;
    if (!isPartialLogin) {
      return PageComponent.getInitialProps?.(ctx) || {};
    }

    const shouldRedirect = await hasValidFreeBagInvite(ctx);
    if (!shouldRedirect) {
      return PageComponent.getInitialProps?.(ctx) || {};
    }

    const redirectUrl = ABSOLUTE_URLS.SUBSCRIBE;
    if (ctx.req && ctx.res) {
      ctx.res.writeHead(302, { Location: redirectUrl });
      ctx.res.end();
    } else {
      externalRedirect(redirectUrl);
    }

    return {};
  };

  return WrappedPageComponent;
};

export default withRedirectFreeBagUser;

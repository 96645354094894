import { FullScreenLoading } from '@bfa/common-ui';
import noop from 'lodash/noop';
import React, { MouseEvent } from 'react';

import Layout from '../../../../components/Layout';

export type PropTypes = {
  onCrossClick?: (event?: MouseEvent) => void;
  onLogoClick?: (event?: MouseEvent) => void;
  hideLogo?: boolean;
  showBoxycharmLogo?: boolean;
};

const Loading = ({
  onCrossClick = noop,
  onLogoClick = noop,
  hideLogo = false,
  showBoxycharmLogo = false,
}: PropTypes) => (
  <Layout
    header={{
      cross: true,
      showBoxycharmLogo,
      hideLogo,
      onCrossClick,
      onLogoClick,
    }}
  >
    <FullScreenLoading loading spinnerWidth={4.4} />
  </Layout>
);

export default Loading;

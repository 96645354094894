import gql from 'graphql-tag';

const GET_QUIZ_EXPERIMENT = gql`
  query getSplashPageBannerButtonExperiments {
    me {
      showSelectPlan: abResource(key: "PUBLIC_SHOW_HOME_PAGE_PLAN")
      isOptimizedFlow: featureFlag(
        key: "PUBLIC_OPTIMIZED_USER_ACQUISITION_FLOW"
      )
      getStartedButtonCohortParam: abResource(
        key: "PUBLIC_GET_STARTED_EXP_COHORT_PARAM"
      )
    }
  }
`;

export default GET_QUIZ_EXPERIMENT;

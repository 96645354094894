import gql from 'graphql-tag';

const GET_REFRESHMENTS_HP_BANNER = gql`
  query getRefreshmentsHpBanner {
    me {
      layout {
        refreshmentsHpBanner: vcmsAsset(
          structure: "RefreshmentsHomepageBanner"
          properties: { contentname: "refreshmentsHomepageBanner" }
        )
        homepageTopBanner: vcmsAsset(
          structure: "HomepageTopBanner"
          properties: { contentname: "homepageTopBanner" }
        )
      }
    }
  }
`;

export default GET_REFRESHMENTS_HP_BANNER;

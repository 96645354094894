import { cookieService } from '@bfa/nextjs-common/services';

import { AnalyticsEventKey } from '../../utils/analytics/constants';
import CookieName from '../../utils/cookies/cookieName';
import { AnalyticsEvent } from '../../utils/types';

export type EventLabelMap = {
  HEADER: string; // Button in header
  HERO: string; // Button on hero section
  STICKY: string; // Sticky button
  LOGIN: string; // Login button
};

/**
 * Hook to return analytics event for 'Get Started' button on splash page.
 *
 * @param type - The button type.
 * @returns Analytics event object.
 */
const useGetStartedAnalytics = (
  type: keyof EventLabelMap = 'HERO'
): AnalyticsEvent => {
  let name: AnalyticsEventKey;
  let labelMap: EventLabelMap;

  const isFreeBagReferee = !!cookieService.getCookie(
    undefined,
    CookieName.IPSY_FREE_BAG_INVITE_COOKIE
  );
  const isGettingReferralGift = !!cookieService.getCookie(
    undefined,
    CookieName.IPSY_REFERRAL_GIFT
  );

  const defaultLabel = 'Clicked Get Started';
  const defaultLoginLabel = 'Clicked Get Started Button from Login';

  if (isFreeBagReferee) {
    name = 'FREE_BAGS';
    labelMap = {
      HEADER: defaultLabel,
      HERO: 'Clicked Get Started on Free Bags Signed Out Homepage',
      STICKY: 'Clicked Get Started on Free Bags Signed Out Homepage',
      LOGIN: defaultLoginLabel,
    };
  } else if (isGettingReferralGift) {
    name = 'SHIPMENT_WEEK_CAMPAIGN';
    labelMap = {
      HEADER: defaultLabel,
      HERO: defaultLabel,
      STICKY: defaultLabel,
      LOGIN: defaultLoginLabel,
    };
  } else {
    name = 'SIGNED_OUT_LANDING_EXPERIENCE';
    labelMap = {
      HEADER: 'Clicked Get Started Button in Header',
      HERO: 'Clicked Get Started Button on Hero Section',
      STICKY: 'Clicked Sticky Get Started Button',
      LOGIN: defaultLoginLabel,
    };
  }

  return {
    name,
    label: labelMap[type],
  };
};

export default useGetStartedAnalytics;

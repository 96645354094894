import { Config } from '@bfa/next-app-config';
import Script from 'next/script';
import React, { FC } from 'react';

const ReCaptchaScript: FC = () => {
  if (Config.public.misc.googleReCaptchaV3Key) {
    return (
      <Script
        src={
          'https://www.recaptcha.net/recaptcha/enterprise.js?render=' +
          Config.public.misc.googleReCaptchaV3Key
        }
      />
    );
  }

  return null;
};

export default ReCaptchaScript;
